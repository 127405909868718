import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

import Layout from "../components/Layout"
import { Container } from "../components/Container"
import { MainBackground } from "../components/MainBackground"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "../components/Icon"
import MyLink from "../utils/checkExternalLink"
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";

export const query = graphql`
  query ICCompanyBySlug($uid: String!) {
    prismicCompany(uid: { eq: $uid }) {
      data {
        address {
          text
        }
        description {
          text
        }
        email {
          text
        }
        logo {
          gatsbyImageData
        }
        subtitle {
          text
        }
        title {
          text
        }
        video {
          html
        }
        web_site {
          text
        }
      }
    }
  }
`

const IChallengeCompanyTemplate = props => {
  const { data, location, path, pageContext } = props

  const { data: pageData } = data?.prismicCompany

  const obj = {
    challengeTitle: pageContext?.iChallengeTitle,
    challengeSlug: pageContext?.iChallengeSlug,
    title: pageData?.title?.text,
    subtitle: pageData?.subtitle?.text,
    description: pageData?.description?.text,
    address: pageData?.address?.text,
    email: pageData?.email?.text,
    logo: pageData?.logo?.gatsbyImageData,
    video: pageData?.video?.html,
    web_site: pageData?.web_site?.text,
  }

  const logo = getImage(obj.logo)

  const breadCrumbs = [
    {
      label: obj.challengeTitle,
      linkUrl: obj.challengeSlug,
    },
    {
      label: obj.title,
      linkUrl: "",
    },
  ]

  return (
    <Layout location={location}>
      <Seo
        pathname={path}
        title={obj.title}
        description={obj.subtitle}
        image={obj.logo}
      />
      <MainBackground type="violet" container>
        <Container>
          <BreadCrumbs list={breadCrumbs} />
          <GridContainer isVideo={obj.video}>
            <ImageBlock>
              <ImageWrapper>
                <GatsbyImage alt="logo" image={logo} />
              </ImageWrapper>
            </ImageBlock>
            <AboutCompany>
              <Brief>Live brief</Brief>
              <Title>{obj.title}</Title>
              <Subtitle>{obj.subtitle}</Subtitle>
            </AboutCompany>
            {obj.video && (
              <VideoWrapper>
                <div dangerouslySetInnerHTML={{ __html: obj.video }} />
              </VideoWrapper>
            )}
            <DescriptionBlock>
              <DescriptionTitle>Description</DescriptionTitle>
              <DescriptionContent>{obj.description}</DescriptionContent>
            </DescriptionBlock>
            <ContactDetails>
              <DetailsList>
                <ListItem>
                  <Icon type="LOCATION" />
                  <ListItemAddress>{obj.address}</ListItemAddress>
                </ListItem>
                <ListItem>
                  <Icon type="EMAIL" />
                  <ListItemAddress>{obj.email}</ListItemAddress>
                </ListItem>
                <MyLink href={obj.web_site}>{obj.web_site}</MyLink>
              </DetailsList>
            </ContactDetails>
          </GridContainer>
        </Container>
      </MainBackground>
    </Layout>
  )
}

IChallengeCompanyTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IChallengeCompanyTemplate

const Brief = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
`

const Title = styled.h1`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 36px;
  color: white;
`

const Subtitle = styled.div`
  line-height: 1.5;
  max-width: 600px;
  margin-top: 8px;
`

const DescriptionTitle = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0;
`

const DescriptionContent = styled.span`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 12px;
  display: inline-block;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    max-width: 710px;
  }
`

const DetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    line-height: 1.5;
    transition: 0.3s ease-out;
    color: ${({ theme }) => theme.colors.primary_color};

    &:hover {
      opacity: 0.8;
    }
  }

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    flex-direction: row;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
`

const ListItemAddress = styled.div`
  margin-left: 4px;
`

const GridContainer = styled.div`
  margin-top: 24px;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "logo"
    "about"
    "contacts"
    "description"
    "video";

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    grid-template-columns: 150px 1fr;
    column-gap: 24px;
    grid-template-areas:
      "logo about"
      ". contacts"
      "description description"
      "video video";
  }

  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    grid-template-columns: 150px 1fr 400px;
    column-gap: 24px;
    grid-template-areas: ${({ isVideo }) =>
      isVideo
        ? `"logo about about"
    ". contacts contacts"
    "description description video"`
        : `"logo about about"
    ". contacts contacts"
    "description description description"`};
  }
`

const ImageBlock = styled.div`
  grid-area: logo;
`

const ImageWrapper = styled.div`
  max-width: 150px;
  background-color: white;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`

const AboutCompany = styled.div`
  grid-area: about;
  margin-top: 16px;
  padding-bottom: 16px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    max-width: 670px;
    background: white;
    opacity: 0.5;
    bottom: 0;
    left: 0;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin-top: 0;
  }
`

const ContactDetails = styled.div`
  grid-area: contacts;
  margin-top: 8px;
`

const DescriptionBlock = styled.div`
  grid-area: description;
  margin-top: 40px;
`

const VideoWrapper = styled.div`
  grid-area: video;
  margin-top: 40px;
  div {
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
  }
`