import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Icon } from "../Icon"

const BreadCrumbs = ({ list }) => {
  const cutString = str => {
    const arr = str.split(" ")

    const strLength = 4

    return arr.length > strLength
      ? `${arr.slice(0, strLength).join(" ")}...`
      : arr.join(" ")
  }
  return (
    <BreadCrumbsList>
      {list.map((el, i) => (
        <BreadCrumbsWrapper key={i}>
          <BreadCrumbsListItem isFirst={i === 0}>
            <Link to={el.linkUrl}>
              {i === 0 && <Icon type="RETURN_ICON" width={18} height={18} />}
              <Label isFirst={i === 0}>{cutString(el.label)}</Label>
            </Link>
          </BreadCrumbsListItem>
          {i < list.length - 1 && <Divider>/</Divider>}
        </BreadCrumbsWrapper>
      ))}
    </BreadCrumbsList>
  )
}

export default BreadCrumbs

const BreadCrumbsList = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin-bottom: 45px;
  }
`
const BreadCrumbsWrapper = styled.div`
  display: contents;
`

const BreadCrumbsListItem = styled.div`
  span {
    font-size: 16px;
    color: white;
    padding-left: ${({ isFirst }) => (isFirst ? "4px" : "")};

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 18px;
    }
  }

  a {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    display: inline-flex;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
    }

    svg {
      flex-shrink: 0;
      transition: 0.3s ease-out;
    }

    &:hover {
      svg {
        transform: translateX(-5px) rotate(-5deg);
      }
    }
  }
`

const Divider = styled.div`
  color: #fff;
`

const Label = styled.span`
  display: block;
  margin-left: ${({ isFirst }) => (isFirst ? "8px" : "")};
`
